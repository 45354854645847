import React, { FunctionComponent } from 'react';
import i18next from 'i18next';

import Loading from 'components/commons/Loading';
import BlogPreview from 'components/commons/BlogPreview';
import Carousel from 'components/commons/Carousel';

import { IBlog } from 'types/blogs.types';
import { ViewContainer } from 'styles/view';

import { IBlogsViewProps } from './types';
import {
  KnowOurArticlesContainer,
  KnowOurArticlesTitle,
  KnowOurArticlesLoadingContainer,
  ExploreMoreContentContainer,
  ExploreMoreContentTitle,
  ExploreMoreContentBlogContainer,
} from './styles';
import './i18n';

const BlogsView: FunctionComponent<IBlogsViewProps> = (props: IBlogsViewProps) => {
  const { loading, items } = props;

  return (
    <ViewContainer data-testid="blogs-view">
      <KnowOurArticlesContainer>
        <KnowOurArticlesTitle>
          {i18next.t<string>('BLOGS_VIEW:KNOW_OUR_ARTICLES_TITLE')}
        </KnowOurArticlesTitle>
        {loading ? (
          <KnowOurArticlesLoadingContainer>
            <Loading />
          </KnowOurArticlesLoadingContainer>
        ) : (
          items
            .slice(0, 2)
            .map((item, i) => <BlogPreview isLarge isReversed={i % 2 === 1} item={item} />)
        )}
      </KnowOurArticlesContainer>
      <ExploreMoreContentContainer>
        <ExploreMoreContentTitle>
          {i18next.t<string>('BLOGS_VIEW:EXPLORE_MORE_CONTENT_TITLE')}
        </ExploreMoreContentTitle>
        <ExploreMoreContentBlogContainer>
          <Carousel
            autoPlay
            items={items.slice(2, items.length)}
            renderItem={(item: IBlog) => <BlogPreview item={item} />}
          />
        </ExploreMoreContentBlogContainer>
      </ExploreMoreContentContainer>
    </ViewContainer>
  );
};

export default BlogsView;
