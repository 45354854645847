import i18next from 'i18next';
import { locales } from 'configs/i18n';

/* ------------------------------------- English ------------------------------------- */

i18next.addResources(locales.EN, 'FAQ_VIEW', {
  TITLE: 'FAQ',
  QUESTION_1_TITLE: 'What commission do you charge for your services?',
  QUESTION_1_DESCRIPTION:
    'We charge 15% of the total rent + cleaning fee according to the surface of the apartment.',
  QUESTION_2_TITLE: 'How do you manage the collection of rents?',
  QUESTION_2_DESCRIPTION:
    'We help you configure the means of payment on different platforms such as Airbnb or Booking. In all cases, you will receive the payment directly to your bank account.',
  QUESTION_3_TITLE: 'What services do you offer?',
  QUESTION_3_DESCRIPTION:
    'We manage everything related to the temporary rental, adapting to the needs of the owner. Learn more about',
  QUESTION_3_DESCRIPTION_LINK: 'our services',
  QUESTION_4_TITLE: 'How do you help me highlight my property?',
  QUESTION_4_DESCRIPTION:
    'You will have an attractive publication, which has a complete description and professional photographs. On the other hand, we set a competitive price according to current supply and demand.',
  QUESTION_5_TITLE: 'How do you select the properties you manage?',
  QUESTION_5_DESCRIPTION:
    'The selection of properties depends on several factors: location, condition, quality, surface area, amenities and profitability potential, among others. We provide service only in CABA.',
});

/* ------------------------------------- Spanish ------------------------------------- */

i18next.addResources(locales.ES, 'FAQ_VIEW', {
  TITLE: 'Preguntas frecuentes',
  QUESTION_1_TITLE: '¿Qué comisión cobran por sus servicios?',
  QUESTION_1_DESCRIPTION:
    'Cobramos el 15% de la renta total + tarifa de limpieza de acuerdo a la superficie del departamento.',
  QUESTION_2_TITLE: '¿Cómo gestionan el cobro de los alquileres?',
  QUESTION_2_DESCRIPTION:
    'Te ayudamos a configurar el medio de pago en las diferentes plataformas como Airbnb o Booking. En todos los casos, vas a recibir el cobro directamente en tu cuenta bancaria.',
  QUESTION_3_TITLE: '¿Qué servicios ofrecen?',
  QUESTION_3_DESCRIPTION:
    'Gestionamos todo lo relativo al alquiler temporario, adaptándonos a las necesidades del propietario. Conocé más acerca de',
  QUESTION_3_DESCRIPTION_LINK: 'nuestros servicios',
  QUESTION_4_TITLE: '¿Cómo me ayudan a destacar mi propiedad?',
  QUESTION_4_DESCRIPTION:
    'Vas a disponer de una publicación atractiva, que cuenta con una descripción completa y fotografías profesionales. Por otro lado, fijamos un precio competitivo de acuerdo a la oferta y demanda vigente.',
  QUESTION_5_TITLE: '¿Cómo seleccionan las propiedades que administran?',
  QUESTION_5_DESCRIPTION:
    'La selección de propiedades depende de varios factores: ubicación, estado, calidad, superficie, amenities y potencial de rentabilidad, entre otros. Brindamos servicio únicamente en CABA.',
});
