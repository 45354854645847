import { AnyAction } from 'redux';
import { all, call, put, takeLatest } from 'redux-saga/effects';

import * as constants from 'redux/constants/blogs.constants';
import { getAll, getOne } from 'services/blogs.services';

export function* blogsGetAll(action: AnyAction): Generator {
  try {
    const { id }: any = action;
    const { data }: any = yield call(getAll, id);
    yield put({ type: constants.BLOGS_ON_GET_ALL_SUCCEEDED, data });
  } catch (error) {
    yield put({ type: constants.BLOGS_ON_GET_ALL_FAILED, error });
  }
}

export function* blogsGetOne(action: AnyAction): Generator {
  try {
    const { id }: any = action;
    if (id) {
      const { data }: any = yield call(getOne, id);
      yield put({ type: constants.BLOGS_ON_GET_ONE_SUCCEEDED, data });
    }
  } catch (error) {
    yield put({ type: constants.BLOGS_ON_GET_ONE_FAILED, error });
  }
}

export function* watchBlogs(): Generator {
  yield all([
    takeLatest(constants.BLOGS_ON_GET_ALL_REQUESTED, blogsGetAll),
    takeLatest(constants.BLOGS_ON_GET_ONE_REQUESTED, blogsGetOne),
  ]);
}
