import i18next from 'i18next';
import { locales } from 'configs/i18n';

/* ------------------------------------- English ------------------------------------- */

i18next.addResources(locales.EN, 'FOOTER', {
  COPYRIGHT: 'All rights reserved',
  BLOG: 'Blog',
  FAQ: 'FAQ',
  CONTACT: 'Contact',
  LOCATION: 'Location',
  ATENTION: 'Office hours: business days from 9 a.m. to 5 p.m.',
  ADDRESS: 'Av. Corrientes 1386, 9th floor. CABA.',
});

/* ------------------------------------- Spanish ------------------------------------- */

i18next.addResources(locales.ES, 'FOOTER', {
  COPYRIGHT: 'Todos los derechos reservados.',
  BLOG: 'Blog',
  FAQ: 'FAQ',
  CONTACT: 'Contacto',
  LOCATION: 'Ubicación',
  ATENTION: 'Horarios de atención: días hábiles de 9 a 17hs.',
  ADDRESS: 'Av. Corrientes 1386, Piso 9. CABA.',
});
